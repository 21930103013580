import React from 'react';

export const Gymo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="78"
      viewBox="0 0 240 78"
      fill="none"
    >
      <g clipPath="url(#clip0_54_462)">
        <path
          d="M12.6383 77.807L9.33222 73.2165H5.04222V77.807H3.56848V66.6592H10.2462C12.5727 66.6592 13.8802 67.8419 13.8802 69.94C13.8802 71.7754 12.8657 72.9099 11.0465 73.1683L14.4181 77.807H12.6339H12.6383ZM5.04222 71.9374H9.9707C11.5756 71.9374 12.3759 71.2804 12.3759 69.9356C12.3759 68.5909 11.5756 67.9338 9.9707 67.9338H5.04222V71.9331V71.9374Z"
          fill="#2B3939"
        />
        <path
          d="M16.1237 72.2312C16.1237 68.7708 18.5464 66.458 22.1979 66.458C25.8494 66.458 28.2721 68.7708 28.2721 72.2312C28.2721 75.6917 25.8494 78.0045 22.1979 78.0045C18.5464 78.0045 16.1237 75.6917 16.1237 72.2312ZM26.7634 72.2312C26.7634 69.5593 24.9311 67.8028 22.1979 67.8028C19.4647 67.8028 17.6324 69.5593 17.6324 72.2312C17.6324 74.9032 19.4647 76.6597 22.1979 76.6597C24.9311 76.6597 26.7634 74.9032 26.7634 72.2312Z"
          fill="#2B3939"
        />
        <path
          d="M41.4308 74.789C41.4308 76.7558 40.0882 77.807 37.5825 77.807H30.8217V66.6592H37.3551C39.7778 66.6592 41.0722 67.6754 41.0722 69.5765C41.0722 70.8248 40.4512 71.6746 39.288 72.0864C40.6786 72.4806 41.4308 73.3961 41.4308 74.7934V74.789ZM32.2954 71.5257H37.3026C38.8069 71.5257 39.5941 70.9212 39.5941 69.7385C39.5941 68.5559 38.8069 67.9338 37.3026 67.9338H32.2954V71.5257ZM39.9221 74.6751C39.9221 73.4267 39.1218 72.8047 37.4819 72.8047H32.291V76.528H37.4819C39.0868 76.528 39.9221 75.906 39.9221 74.6751Z"
          fill="#2B3939"
        />
        <path
          d="M43.4117 72.2312C43.4117 68.7708 45.8344 66.458 49.486 66.458C53.1375 66.458 55.5602 68.7708 55.5602 72.2312C55.5602 75.6917 53.1375 78.0045 49.486 78.0045C45.8344 78.0045 43.4117 75.6917 43.4117 72.2312ZM54.0515 72.2312C54.0515 69.5593 52.2192 67.8028 49.486 67.8028C46.7528 67.8028 44.9205 69.5593 44.9205 72.2312C44.9205 74.9032 46.7528 76.6597 49.486 76.6597C52.2192 76.6597 54.0515 74.9032 54.0515 72.2312Z"
          fill="#2B3939"
        />
        <path
          d="M67.197 67.9338H62.7277V77.8026H61.254V67.9338H56.7847V66.6548H67.197V67.9338Z"
          fill="#2B3939"
        />
        <path
          d="M68.5396 76.3444L69.2436 75.1485C70.4856 76.1166 72.1561 76.6554 73.9272 76.6554C76.2362 76.6554 77.465 75.9677 77.465 74.6229C77.465 73.5585 76.468 73.0986 73.831 72.919C70.3588 72.6912 69.0031 71.8195 69.0031 69.8221C69.0031 67.7239 70.7873 66.4624 73.7829 66.4624C75.5671 66.4624 77.1065 66.9223 78.3178 67.8072L77.6618 69.003C76.5992 68.2145 75.2391 67.8072 73.6692 67.8072C71.7057 67.8072 70.5118 68.543 70.5118 69.7739C70.5118 70.8383 71.4958 71.2983 74.1459 71.4778C77.6181 71.7056 78.9738 72.5773 78.9738 74.5791C78.9738 76.7911 77.1721 78.0045 73.9141 78.0045C71.8194 78.0045 69.904 77.4132 68.5439 76.3487L68.5396 76.3444Z"
          fill="#2B3939"
        />
        <path
          d="M107.294 77.8074L105.803 76.5283C104.819 77.4964 103.398 78.0045 101.727 78.0045C99.09 78.0045 97.4545 76.743 97.4545 74.693C97.4545 72.8401 98.8276 72.0385 100.14 71.6268C99.3699 70.8558 99.0244 70.1988 99.0244 69.3315C99.0244 67.5268 100.384 66.4624 102.51 66.4624C104.635 66.4624 105.899 67.4962 105.899 69.1694C105.899 71.0398 104.294 71.6793 102.641 72.0867L105.685 74.7105C106.192 73.9396 106.52 72.9891 106.651 71.8721L108.042 72.021C107.862 73.3964 107.386 74.6098 106.669 75.5471L109.306 77.8118H107.294V77.8074ZM104.841 75.6917L101.045 72.4459C99.9646 72.7394 98.8495 73.2825 98.8495 74.6098C98.8495 75.937 99.9777 76.7736 101.797 76.7736C102.991 76.7736 104.023 76.3969 104.841 75.6917ZM100.419 69.314C100.419 69.9535 100.73 70.431 101.666 71.2501C103.664 70.7726 104.596 70.4616 104.596 69.3315C104.596 68.2977 103.778 67.6933 102.501 67.6933C101.224 67.6933 100.424 68.3021 100.424 69.3184L100.419 69.314Z"
          fill="#2B3939"
        />
        <path
          d="M128.171 66.6548H129.645V77.8026H128.171V66.6548Z"
          fill="#2B3939"
        />
        <path
          d="M143.184 66.6548V77.8026H141.793L134.215 68.1616V77.8026H132.789V66.6548H134.818L141.758 75.4767V66.6548H143.184Z"
          fill="#2B3939"
        />
        <path
          d="M156.719 66.6548V77.8026H155.328L147.749 68.1616V77.8026H146.324V66.6548H148.353L155.293 75.4767V66.6548H156.719Z"
          fill="#2B3939"
        />
        <path
          d="M159.273 72.2312C159.273 68.7708 161.695 66.458 165.347 66.458C168.998 66.458 171.421 68.7708 171.421 72.2312C171.421 75.6917 168.998 78.0045 165.347 78.0045C161.695 78.0045 159.273 75.6917 159.273 72.2312ZM169.912 72.2312C169.912 69.5593 168.08 67.8028 165.347 67.8028C162.614 67.8028 160.781 69.5593 160.781 72.2312C160.781 74.9032 162.614 76.6597 165.347 76.6597C168.08 76.6597 169.912 74.9032 169.912 72.2312Z"
          fill="#2B3939"
        />
        <path
          d="M183.946 66.6548L178.903 77.8026H177.412L172.37 66.6548H173.992L178.199 76.0505L182.389 66.6548H183.946Z"
          fill="#2B3939"
        />
        <path
          d="M192.556 74.8678H186.451L185.078 77.8026H183.539L188.795 66.6548H190.287L195.543 77.8026H193.938L192.565 74.8678H192.556ZM187.037 73.5888H191.966L189.513 68.258L187.042 73.5888H187.037Z"
          fill="#2B3939"
        />
        <path
          d="M205.059 67.9338H200.59V77.8026H199.116V67.9338H194.647V66.6548H205.059V67.9338Z"
          fill="#2B3939"
        />
        <path
          d="M207.302 66.6548H208.776V77.8026H207.302V66.6548Z"
          fill="#2B3939"
        />
        <path
          d="M211.33 72.2312C211.33 68.7708 213.753 66.458 217.404 66.458C221.056 66.458 223.478 68.7708 223.478 72.2312C223.478 75.6917 221.056 78.0045 217.404 78.0045C213.753 78.0045 211.33 75.6917 211.33 72.2312ZM221.97 72.2312C221.97 69.5593 220.137 67.8028 217.404 67.8028C214.671 67.8028 212.839 69.5593 212.839 72.2312C212.839 74.9032 214.671 76.6597 217.404 76.6597C220.137 76.6597 221.97 74.9032 221.97 72.2312Z"
          fill="#2B3939"
        />
        <path
          d="M236.427 66.6548V77.8026H235.037L227.458 68.1616V77.8026H226.032V66.6548H228.061L235.002 75.4767V66.6548H236.427Z"
          fill="#2B3939"
        />
        <path
          d="M20.4486 34.1443H46.5516C45.5239 37.0528 43.8621 39.6635 41.7281 41.8011C38.0284 45.5068 32.9163 47.8021 27.2662 47.8021C21.6162 47.8021 16.5084 45.5112 12.8044 41.8011L7.98523 46.6282C12.9181 51.5691 19.7357 54.6266 27.2662 54.6266C34.7967 54.6266 41.6144 51.5691 46.5472 46.6282C46.9495 46.2252 47.3387 45.8091 47.7148 45.3798C50.5136 42.2041 52.5865 38.3713 53.671 34.1443C54.2307 31.9629 54.5281 29.672 54.5281 27.3154H27.2618L20.4442 34.1443H20.4486Z"
          fill="#2B3939"
        />
        <path
          d="M27.2663 6.83327C32.9119 6.83327 38.0241 9.12416 41.7281 12.8343L46.5473 8.00719C41.6144 3.06183 34.7967 0 27.2663 0C12.2097 0 0 12.2298 0 27.3155H6.81766C6.81766 16.0012 15.9749 6.83327 27.2663 6.83327Z"
          fill="#2B3939"
        />
        <path
          d="M88.9357 20.4998L92.8715 27.3243L108.623 0H100.752L88.9357 20.4998Z"
          fill="#2B3939"
        />
        <path
          d="M121.017 54.6267H127.834V25.1342L144.876 54.6267L148.803 47.811L121.017 0V54.6267Z"
          fill="#2B3939"
        />
        <path
          d="M152.739 40.9865L161.918 25.1342V54.6267H168.732V0L148.803 34.1576L152.739 40.9865Z"
          fill="#2B3939"
        />
        <path
          d="M84.9956 27.3375L69.2436 0H61.3721L81.5889 35.0774V54.6267H88.4066V40.1016V35.0774V33.229L84.9999 27.3243L84.9956 27.3375Z"
          fill="#2B3939"
        />
        <path
          d="M236.344 13.6575C232.885 7.6609 227.502 3.47333 221.423 1.42773L230.44 17.0698C236.086 26.8685 232.736 39.3962 222.954 45.0511C218.419 47.6749 213.293 48.3583 208.544 47.3596L212.734 54.6265C217.369 54.6265 222.066 53.4526 226.365 50.9645C239.405 43.4217 243.875 26.7196 236.344 13.6575Z"
          fill="#2B3939"
        />
        <path
          d="M202.505 9.57534C207.04 6.95154 212.165 6.26821 216.914 7.26692L212.729 0C208.094 0 203.397 1.17392 199.098 3.66193C186.058 11.2048 181.589 27.9069 189.119 40.9689C192.578 46.9656 197.961 51.1531 204.04 53.1987L195.023 37.5567C189.377 27.758 192.727 15.2303 202.509 9.57534H202.505Z"
          fill="#2B3939"
        />
      </g>
      <defs>
        <clipPath id="clip0_54_462">
          <rect width="240" height="78" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
