import classes from './UseCases.module.scss';
import Card from 'components/card/Card';
import buildingPic from '../../../assets/images/bg-card-building.png';
import energyPic from '../../../assets/images/bg-card-energy.png';
import hydroPic from '../../../assets/images/bg-card-hydro.png';
import insurancePic from '../../../assets/images/bg-card-insurance.png';
import transportPic from '../../../assets/images/bg-card-transport.png';
import healthcarePic from '../../../assets/images/bg-card-healthcare.png';
import telcoPic from '../../../assets/images/bg-card-telco.png';
import administrationPic from '../../../assets/images/bg-card-administration.png';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

const UseCases: FC = () => {
  const { t } = useTranslation('useCases');

  const CARDS = [
    {
      title: t('cardBuilding.title'),
      description: <p>{t('cardBuilding.description')}</p>,
      image: buildingPic
    },
    {
      title: t('cardEnergy.title'),
      description: <p>{t('cardEnergy.description')}</p>,
      image: energyPic
    },
    {
      title: t('cardHydro.title'),
      description: <p>{t('cardHydro.description')}</p>,
      image: hydroPic
    },
    {
      title: t('cardInsurance.title'),
      description: <p>{t('cardInsurance.description')}</p>,
      image: insurancePic
    },
    {
      title: t('cardLogistic.title'),
      description: <p>{t('cardLogistic.description')}</p>,
      image: transportPic
    },
    {
      title: t('cardAdm.title'),
      description: <p>{t('cardAdm.description')}</p>,
      image: administrationPic
    },
    {
      title: t('cardTelco.title'),
      description: <p>{t('cardTelco.description')}</p>,
      image: telcoPic
    },
    {
      title: t('cardHealth.title'),
      description: <p>{t('cardHealth.description')}</p>,
      image: healthcarePic
    }
  ];

  return (
    <div id="industries" className={classes.container}>
      <h3 className={classes.title}>{t('title')}</h3>
      <div className={classes.divider}></div>
      <div className={classes.cardContainer}>
        {CARDS.map(card => (
          <Card
            key={card.title}
            className={classes.card}
            title={card.title}
            description={card.description}
            imgSrc={card.image}
          />
        ))}
      </div>
    </div>
  );
};

export default UseCases;
