export const Tridimensional = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_176_151)">
        <path
          d="M61.4185 40.1796V53.8186L52.8587 48.8678V35.9527C52.8582 35.6475 52.7779 35.3478 52.6256 35.0835C52.4734 34.8193 52.2546 34.5998 51.9911 34.447L40.8379 27.9883V18.0867L52.6157 24.9074L54.3508 21.8914L39.9703 13.5651C39.7066 13.4124 39.4074 13.332 39.1028 13.332C38.7982 13.332 38.499 13.4124 38.2352 13.5651L23.8525 21.8914L25.5876 24.9074L37.3677 18.0867V27.9883L26.2238 34.447C25.9603 34.5998 25.7415 34.8193 25.5893 35.0835C25.437 35.3478 25.3567 35.6475 25.3562 35.9527V48.8678L16.7964 53.8186V40.1796H13.3262V56.8322C13.3267 57.1377 13.4071 57.4377 13.5593 57.7023C13.7115 57.9669 13.9303 58.1868 14.1937 58.3402L28.5766 66.6665L30.3117 63.6505L18.5315 56.8322L27.0913 51.8791L38.2468 58.3378C38.5117 58.4872 38.8105 58.5657 39.1144 58.5657C39.4183 58.5657 39.717 58.4872 39.9819 58.3378L51.1352 51.8791L59.6949 56.8322L47.9171 63.6505L49.6522 66.6665L64.0327 58.3402C64.2961 58.1868 64.5149 57.9669 64.6671 57.7023C64.8193 57.4377 64.8997 57.1377 64.9002 56.8322V40.1796H61.4185ZM47.6534 35.948L39.112 40.8941L30.5684 35.948L39.112 31.0019L47.6534 35.948ZM37.3769 43.9078V53.8209L28.8172 48.8701V38.9524L37.3769 43.9078ZM49.407 38.957V48.8701L40.8471 53.8209V43.9078L49.407 38.957Z"
          fill="#94A8A6"
        />
      </g>
      <defs>
        <clipPath id="clip0_176_151">
          <rect
            width="51.5556"
            height="53.3333"
            fill="white"
            transform="translate(13.333 13.3334)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
