import Card from 'components/card/Card';
import React, { FC } from 'react';
import classes from './Applications.module.scss';
import { useTranslation } from 'react-i18next';
import { Aerial, Fix, Learn, Marketing, Robot, Video } from 'components/icons';

const Applications: FC = () => {
  const { t } = useTranslation('applications');
  const cards = [
    {
      icon: <Aerial />,
      title: t('data.title'),
      description: (
        <p>
          {t('data.description1')}
          <b>{t('data.descriptionHighlight')}</b>
          {t('data.description3')}
        </p>
      )
    },
    {
      icon: <Video />,
      title: t('surveillance.title'),
      description: (
        <p>
          {t('surveillance.description1')}
          <b>{t('surveillance.descriptionHighlight')}</b>
          {t('surveillance.description3')}
        </p>
      )
    },
    {
      icon: <Fix />,
      title: t('construction.title'),
      description: (
        <p>
          {t('construction.description1')}
          <b>{t('construction.descriptionHighlight')}</b>
          {t('construction.description3')}
        </p>
      )
    },
    {
      icon: <Robot />,
      title: t('aid.title'),
      description: (
        <p>
          {t('aid.description1')}
          <b>{t('aid.descriptionHighlight')}</b>
          {t('aid.description3')}
        </p>
      )
    },
    {
      icon: <Learn />,
      title: t('training.title'),
      description: <p>{t('training.description')}</p>
    },
    {
      icon: <Marketing />,
      title: t('advertising.title'),
      description: <p>{t('advertising.description')}</p>
    }
  ];
  return (
    <div id="applications" className={classes.container}>
      <h3>{t('title')}</h3>
      <div className={classes.divider}></div>
      <div className={classes.cardContainer}>
        {cards.map(card => (
          <Card
            key={card.title}
            className={classes.card}
            icon={card.icon}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Applications;
