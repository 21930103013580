export const Target = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_176_55)">
        <path
          d="M57.5833 38.3285C57.1829 34.2455 55.3782 30.4279 52.4772 27.5269C49.5763 24.626 45.7587 22.8213 41.6757 22.4209V13.3334H38.3282V22.4164C34.2442 22.818 30.4258 24.6235 27.5239 27.525C24.622 30.4265 22.8161 34.2446 22.4139 38.3285H13.333V41.6761H22.4139C22.8145 45.7603 24.62 49.579 27.5223 52.4805C30.4246 55.382 34.2438 57.1864 38.3282 57.5859V66.6667H41.6757V57.5859C45.7591 57.1854 49.5769 55.3804 52.4779 52.479C55.3789 49.5776 57.1833 45.7595 57.5833 41.6761H66.6664V38.3285H57.5833ZM38.3282 47.4785V54.2294C35.1233 53.8681 32.136 52.4293 29.8555 50.1488C27.5749 47.8682 26.1362 44.8809 25.7748 41.6761H32.5258V38.3285H25.7815C26.1427 35.1242 27.5815 32.1376 29.8623 29.8581C32.143 27.5786 35.1304 26.1414 38.3348 25.7818V32.5261H41.6824V25.7818C44.886 26.142 47.8721 27.5796 50.1517 29.8592C52.4312 32.1387 53.8689 35.1249 54.2291 38.3285H47.4849V41.6761H54.2336C53.8717 44.88 52.433 47.8661 50.1529 50.1458C47.8728 52.4255 44.8863 53.8637 41.6824 54.225V47.4785H38.3282Z"
          fill="#94A8A6"
        />
      </g>
      <defs>
        <clipPath id="clip0_176_55">
          <rect
            width="53.3333"
            height="53.3333"
            fill="white"
            transform="translate(13.333 13.3334)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
