import { FC } from 'react';
import classes from './OurRobots.module.scss';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import g02 from '../../../assets/images/g02.png';
import g0 from '../../../assets/images/g0.png';
import b2 from '../../../assets/images/b2.png';
import { Check } from 'components/icons';

const OurRobots: FC = () => {
  const { t } = useTranslation('ourRobot');

  const CARDS = [
    {
      title: t('g02.title'),
      description: (
        <div className={classes.description}>
          <div className={classes.info}>
            <h5>
              {t('dimension')}
              <p>70x31x40 cm</p>
            </h5>
            <h5>
              {t('weight')}
              <p>15 kg</p>
            </h5>
            <h5>
              {t('payload')}
              <p>7-8 kg</p>
            </h5>
            <h5>
              {t('controller')}
              <Check />
            </h5>
          </div>
          <p>{t('g02.par')}</p>
        </div>
      ),
      image: g02
    },
    {
      title: t('g0.title'),
      description: (
        <div className={classes.description}>
          <div className={classes.info}>
            <h5>
              {t('dimension')}
              <p>65x31x60 cm</p>
            </h5>
            <h5>
              {t('weight')}
              <p>22 kg</p>
            </h5>
            <h5>
              {t('payload')}
              <p>13 kg</p>
            </h5>
            <h5>
              {t('controller')}
              <Check />
            </h5>
          </div>
          <p>{t('g0.par')}</p>
        </div>
      ),
      image: g0
    },
    {
      title: t('b2.title'),
      description: (
        <div className={classes.description}>
          <div className={classes.info}>
            <h5>
              {t('dimension')}
              <p>110x45x65 cm</p>
            </h5>
            <h5>
              {t('weight')}
              <p>60 kg</p>
            </h5>
            <h5>
              {t('payload')}
              <p>40 kg</p>
            </h5>
            <h5>
              {t('controller')}
              <Check />
            </h5>
          </div>
          <p>{t('b2.par')}</p>
        </div>
      ),
      image: b2
    }
  ];

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <h3>{t('title')}</h3>
        <div className={classes.divider}></div>
      </div>
      <div className={classes.banner}>
        <div className={classes.image}></div>
        <div className={classes.text}>
          <div>
            <h4>{t('banner')}</h4>
          </div>
          <div className={classes.imageText}></div>
        </div>
      </div>
      <div className={classes.cardContainer}>
        {CARDS.map(card => (
          <Card
            description={card.description}
            title={card.title}
            className={classes.card}
            key={card.title}
            imgSrc={card.image}
          />
        ))}
      </div>
    </div>
  );
};

export default OurRobots;
