import { FC } from 'react';
import classes from './Feature.module.scss';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import { AI, Fix, Target, Tridimensional } from 'components/icons';

const Feature: FC = () => {
  const { t } = useTranslation('feature');

  const CARDS = [
    {
      icon: <Tridimensional />,
      title: t('tridimensional.title'),
      description: (
        <p>
          {t('tridimensional.description1')}
          <b> {t('tridimensional.descriptionHighlight')}</b>
          {t('tridimensional.description3')}
        </p>
      )
    },
    {
      icon: <AI />,
      title: t('ai.title'),
      description: (
        <p>
          {t('ai.description1')}
          <b> {t('ai.descriptionHighlight')}</b>
          {t('ai.description3')}
        </p>
      )
    },
    {
      icon: <Target />,
      title: t('target.title'),
      description: (
        <p>
          {t('target.description1')}
          <b> {t('target.descriptionHighlight')}</b>
          {t('target.description3')}
        </p>
      )
    },
    {
      icon: <Fix />,
      title: t('fix.title'),
      description: (
        <p>
          {t('fix.description1')}
          <b> {t('fix.descriptionHighlight')}</b>
          {t('fix.description3')}
        </p>
      )
    }
  ];
  return (
    <div className={classes.container}>
      <h3>{t('title')}</h3>
      <div className={classes.divider}></div>
      <div className={classes.cardContainer}>
        {CARDS.map(card => (
          <Card
            key={card.title}
            className={classes.card}
            icon={card.icon}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Feature;
