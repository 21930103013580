import React, { FC } from 'react';
import classes from './Card.module.scss';
interface CardProps {
  icon?: React.ReactNode;
  title: string;
  description: string | JSX.Element;
  className?: string;
  imgSrc?: string;
}

const Card: FC<CardProps> = ({
  icon,
  title,
  description,
  className,
  imgSrc
}) => {
  return (
    <div className={`${classes.container} ${className}`}>
      {imgSrc ? (
        <div className={classes.imageContainer}>
          <img alt={imgSrc} src={imgSrc} />
          <h5>{title}</h5>
        </div>
      ) : null}
      {icon ? <div className={classes.icon}>{icon}</div> : null}
      {!imgSrc ? <h5>{title}</h5> : null}
      <div className={classes.description}>{description}</div>
    </div>
  );
};

export default Card;
