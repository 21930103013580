export const Learn = () => {
  return (
    <svg
      width="80"
      height="81"
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_176_206)">
        <path
          d="M70.6837 30.6588L39.7672 14.0111C39.5515 13.8944 39.3102 13.8333 39.0649 13.8333C38.8196 13.8333 38.5782 13.8944 38.3625 14.0111L7.44992 30.6588C7.21468 30.7858 7.01818 30.974 6.88122 31.2036C6.74426 31.4331 6.67188 31.6955 6.67188 31.9628C6.67188 32.2301 6.74426 32.4924 6.88122 32.722C7.01818 32.9516 7.21468 33.1398 7.44992 33.2668L20.3219 40.1997H19.7588V55.7804C19.7588 62.166 28.2408 67.1667 39.0698 67.1667C49.8989 67.1667 58.3787 62.166 58.3787 55.7804V40.1997C58.3638 40.1093 58.3401 40.0206 58.3077 39.9349L70.6877 33.2668C70.9235 33.1403 71.1205 32.9522 71.2579 32.7226C71.3953 32.4929 71.4678 32.2304 71.4678 31.9628C71.4678 31.6952 71.3953 31.4326 71.2579 31.203C71.1205 30.9734 70.9235 30.7853 70.6877 30.6588M55.4151 55.7804C55.4151 60.3464 47.9291 64.203 39.0698 64.203C30.2106 64.203 22.7224 60.3464 22.7224 55.7804V41.4918L38.3664 49.9164C38.5825 50.0319 38.8237 50.0924 39.0688 50.0924C39.3138 50.0924 39.5551 50.0319 39.7712 49.9164L55.4151 41.4918V55.7804ZM55.8242 37.9058L39.7851 28.9814L38.3447 31.5696L52.7222 39.5714L39.0619 46.9271L11.2711 31.9628L39.0619 16.9985L66.8527 31.9628L55.8242 37.9058Z"
          fill="#313535"
        />
      </g>
      <defs>
        <clipPath id="clip0_176_206">
          <rect
            width="64.7966"
            height="53.3333"
            fill="white"
            transform="translate(6.66699 13.8333)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
