import { ClickAwayListener, Tooltip } from '@mui/material';
import eng from 'assets/images/flag_eng.png';
import ita from 'assets/images/flag_ita.png';
import gr from 'assets/images/gr.png';
import classes from './LangMenu.module.scss';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LangMenu: FC = () => {
  const { t, i18n } = useTranslation('langMenu');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const path = document.location.pathname.concat('');
  const loc = path.slice(0, -2);

  const onChangeLanguage = useCallback(
    (language: string) => i18n.changeLanguage(language),
    [i18n]
  );

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(prev => !prev);

  const langListElement = () => {
    return (
      <div className={classes.langButtonContainer}>
        <div
          onClick={() => {
            onChangeLanguage('it');
            navigate(`${loc}it`);
          }}
        >
          <img src={ita} alt="flag_ita" />
          <input type="radio" id="ita" name="lang" value="it" />
          <label
            htmlFor="ita"
            className={i18n.language.includes('it') ? 'blue' : 'black'}
          >
            <p>{t('it')}</p>
          </label>
        </div>
        <div
          onClick={() => {
            onChangeLanguage('en');
            navigate(`${loc}en`);
          }}
        >
          <img src={eng} alt="flag_eng" />
          <input type="radio" id="eng" name="lang" value="en" />
          <label
            htmlFor="eng"
            className={i18n.language.includes('en') ? 'blue' : 'black'}
          >
            <p>{t('en')}</p>
          </label>
        </div>
        <div
          onClick={() => {
            onChangeLanguage('gr');
            navigate(`${loc}gr`);
          }}
        >
          <img src={gr} alt="flag_gr" />
          <input type="radio" id="gr" name="lang" value="gr" />
          <label
            htmlFor="gr"
            className={i18n.language.includes('gr') ? classes.blue : ''}
          >
            <p>{t('gr')}</p>
          </label>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            slotProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'white',
                  border: '1px solid #96acab',
                  borderRadius: 0,
                  boxShadow: '5px 5px 20px  grey'
                }
              },
              arrow: {
                sx: {
                  color: 'white',
                  '&::before': {
                    border: '1px solid #96acab',
                    boxSizing: 'border-box'
                  }
                }
              }
            }}
            PopperProps={{
              disablePortal: true
            }}
            arrow
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={langListElement()}
          >
            <button className={classes.button} onClick={handleTooltipOpen}>
              <img
                className={classes.flag}
                src={
                  i18n.language.includes('it')
                    ? ita
                    : i18n.language.includes('gr')
                    ? gr
                    : eng
                }
                alt="lang_flag"
              />
              <p>
                {i18n.language.includes('it')
                  ? 'Italiano'
                  : i18n.language.includes('gr')
                  ? 'Ελληνικά'
                  : 'English'}
              </p>
            </button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default LangMenu;
