export const Marketing = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_176_311)">
        <path
          d="M68.8117 13.536C68.6149 13.4149 68.3904 13.3458 68.1595 13.3354C67.9286 13.3249 67.6988 13.3735 67.4919 13.4764L38.851 27.7968H17.4761C17.1165 27.7968 16.7716 27.9397 16.5173 28.194C16.263 28.4482 16.1202 28.7931 16.1202 29.1527V31.5518C14.1057 31.8674 12.2702 32.8923 10.9446 34.4416C9.61904 35.991 8.89062 37.963 8.89062 40.002C8.89062 42.041 9.61904 44.013 10.9446 45.5624C12.2702 47.1118 14.1057 48.1366 16.1202 48.4522V50.8495C16.1202 51.2091 16.263 51.554 16.5173 51.8083C16.7716 52.0625 17.1165 52.2054 17.4761 52.2054H28.3598V65.3127C28.3598 65.6724 28.5026 66.0172 28.7569 66.2715C29.0112 66.5258 29.3561 66.6687 29.7157 66.6687H39.171C39.5306 66.6687 39.8755 66.5258 40.1298 66.2715C40.3841 66.0172 40.5269 65.6724 40.5269 65.3127V53.0425L67.4919 66.524C67.6799 66.6182 67.8873 66.6671 68.0976 66.6669C68.2758 66.6676 68.4525 66.633 68.6173 66.5651C68.7821 66.4972 68.9319 66.3974 69.0579 66.2713C69.184 66.1453 69.2839 65.9955 69.3518 65.8307C69.4197 65.6658 69.4542 65.4892 69.4535 65.3109V14.6895C69.453 14.4586 69.3937 14.2316 69.2815 14.0299C69.1692 13.8281 69.0076 13.6582 68.8117 13.536ZM11.6004 40.0002C11.6041 38.6803 12.0519 37.4 12.8718 36.3656C13.6917 35.3313 14.8359 34.603 16.1202 34.2981V45.7024C14.8358 45.3977 13.6914 44.6695 12.8715 43.6351C12.0515 42.6006 11.6038 41.3202 11.6004 40.0002ZM18.832 30.5087H37.8151V49.4917H18.832V30.5087ZM31.0716 52.2036H37.8151V63.955H31.0716V52.2036ZM66.7416 63.1179L40.5269 50.0106V29.9916L66.7416 16.8843V63.1179Z"
          fill="#313535"
        />
      </g>
      <defs>
        <clipPath id="clip0_176_311">
          <rect
            width="60.565"
            height="53.3333"
            fill="white"
            transform="translate(8.88867 13.3333)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
