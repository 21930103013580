import React, { FC } from 'react';
import classes from './ContactsBanner.module.scss';
import { useTranslation } from 'react-i18next';

const ContactsBanner: FC = () => {
  const { t } = useTranslation('contactsBanner');

  return (
    <div id="contact-us" className={classes.container}>
      <div className={classes.text}>
        <h4>{t('title')}</h4>
        <span className="sub">{t('description')}</span>
      </div>
      <div className={classes.image}></div>
    </div>
  );
};

export default ContactsBanner;
