import Footer from 'views/home/footer/Footer';
import classes from './Privacy.module.scss';
import { ArrowBackward } from 'components/icons';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
  const { t } = useTranslation('privacy');

  return (
    <div className={classes.bodyContainer}>
      <div className={classes.privacyContainer}>
        <div className={classes.content}>
          <h1>
            <ArrowBackward />
            PRIVACY POLICY
          </h1>
          <div className={classes.subtitle}>
            <h5>{t('policy')}</h5>
          </div>
          <p>
            {t('policy_p_1')}
            <a href="mailto:info@eagleprojects.it">info@eagleprojects.it</a>
            {t('policy_p_2')}
          </p>
          <div className={classes.subtitle}>
            <h5>{t('consent')}</h5>
          </div>
          <p>{t('consent_p')}</p>
          <div className={classes.subtitle}>
            <h5>{t('cookies')}</h5>
          </div>
          <p>{t('cookies_p_1')}</p>
          <p>{t('cookies_p_2')}</p>
          <p>{t('cookies_p_3')}</p>
          <p>{t('cookies_p_4')}</p>
          <p>
            {t('cookies_p_5')}&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://umami.is/docs/faq"
            >
              {t('cookies_p_6')}
            </a>
            &nbsp;{t('cookies_p_7')}
          </p>
          <p>{t('cookies_p_8')}</p>
          <p>{t('cookies_p_9')}</p>
          <div className={classes.subtitle}>
            <h5>{t('deletionCookies')}</h5>
          </div>
          <p>{t('deletionCookies_p_1')}</p>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
              >
                <p>Mozilla Firefox</p>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.google.com/accounts/answer/61416?hl=en"
              >
                <p>Google Chrome</p>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d"
              >
                <p>Internet Explorer</p>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"
              >
                <p>Opera</p>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.apple.com/it-it/HT201265"
              >
                <p>Safari</p>
              </a>
            </li>
          </ul>
          <div className={classes.subtitle}>
            <h5>{t('controller')}</h5>
          </div>
          <p>
            {t('controller_p_1')}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://www.eagleprojects.it'}
            >
              www.eagleprojects.it
            </a>
            {t('controller_p_2')}
            <a href="mailto: info@eagleprojects.it">
              {' '}
              info@eagleprojects.it
            </a>. {t('controller_p_3')}
          </p>
          <div className={classes.subtitle}>
            <h5>{t('collected')}</h5>
          </div>
          <p>{t('collected_p_1')}</p>
          <p>{t('collected_p_2')}</p>
          <div className={classes.subtitle}>
            <h5>{t('dataProvided')}</h5>
          </div>
          <p>{t('provided_p_1')}</p>
          <div className={classes.subtitle}>
            <h5>{t('rights')}</h5>
          </div>
          <p>{t('rights_p')}</p>
          <p>{t('first')}</p>
          <p>{t('second')}</p>
          <ul>
            <li>
              <p>a&#41; {t('second_a')}</p>
            </li>
            <li>
              <p>b&#41; {t('second_b')}</p>
            </li>
            <li>
              <p>c&#41; {t('second_c')}</p>
            </li>
            <li>
              <p>d&#41; {t('second_d')}</p>
            </li>
            <li>
              <p>e&#41; {t('second_e')}</p>
            </li>
          </ul>
          <p>{t('third')}</p>
          <ul>
            <li>
              <p>a&#41; {t('third_a')}</p>
            </li>
            <li>
              <p>b&#41; {t('third_b')}</p>
            </li>
            <li>
              <p>c&#41; {t('third_c')}</p>
            </li>
          </ul>
          <p>{t('fourth')}</p>
          <ul>
            <li>
              <p>a&#41; {t('fourth_a')}</p>
            </li>
            <li>
              <p>b&#41; {t('fourth_b')}</p>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
