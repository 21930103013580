import { FC } from 'react';
import classes from './AboutUs.module.scss';
import { useTranslation } from 'react-i18next';

const AboutUs: FC = () => {
  const { t } = useTranslation('aboutUs');

  return (
    <div className={classes.generalContainer}>
      <div className={classes.textContainer}>
        <h2>{t('title')}</h2>
        <p>{t('par1')}</p>
        <p>
          {t('par2')}
          <a href="https://www.unitree.com/" target="_blank" rel="noreferrer">
            <b>Unitree</b>
          </a>
          {t('par2a')}
        </p>
        <p>
          {t('par3')}
          <b>{t('par3highlight')}</b>
          {t('par3a')}
        </p>
      </div>
      <div className={classes.image}></div>
    </div>
  );
};

export default AboutUs;
