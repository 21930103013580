import { FC } from 'react';
import classes from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer: FC = () => {
  const { t, i18n } = useTranslation('footer');

  return (
    <footer className={classes.container}>
      <div className={classes.leftContainer}>
        <div className={classes.subLeftContainer}>
          <h5>eagleprojects s.p.a.</h5>
          <div className={classes.subSubLeft}>
            <div className={classes.subLeftLeft}>
              <div>
                <p>{t('address')}</p>
                <b>Strada San Galigano, 12/A - 06124 Perugia (PG)</b>
              </div>
              <div>
                <p>Email</p>
                <span>
                  <b>eagleprojects@pec.it</b>
                  <b>info@eagleprojects.it</b>
                </span>
              </div>
            </div>
            <div className={classes.subLeftRight}>
              <div>
                <p>{t('phone')}</p>
                <b> +39 075 7750322</b>
              </div>
              <div>
                <p>{t('vat')}</p>
                <b>03489760540</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <p>© 2023 Eagleprojects SpA | {t('reserved')}</p>
        <Link to={`/privacy/${i18n.language}`}>
          <b>{t('privacy')}</b>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
