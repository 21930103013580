import classes from './Hero.module.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Hero: FC = () => {
  const { t } = useTranslation('hero');
  return (
    <div id="hero" className={classes.hero}>
      <div className={classes.content}>
        <h1>{t('title')}</h1>
        <h3>{t('subtitle')}</h3>
        <span className="sub">{t('par')}</span>
      </div>
    </div>
  );
};

export default Hero;
