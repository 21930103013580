export const Video = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_176_218)">
        <path
          d="M69.0069 22.3849C68.7626 22.2599 68.4885 22.2053 68.215 22.227C67.9416 22.2487 67.6795 22.3459 67.458 22.5078L53.7565 32.473V23.7116C53.7565 23.3171 53.5999 22.9388 53.321 22.6599C53.042 22.3809 52.6636 22.2242 52.2691 22.2242H12.6065C12.212 22.2242 11.8338 22.3809 11.5549 22.6599C11.276 22.9388 11.1191 23.3171 11.1191 23.7116V55.4417C11.1191 55.8361 11.276 56.2145 11.5549 56.4934C11.8338 56.7723 12.212 56.929 12.6065 56.929H52.2691C52.6636 56.929 53.042 56.7723 53.321 56.4934C53.5999 56.2145 53.7565 55.8361 53.7565 55.4417V46.9955L67.4283 57.4704C67.6492 57.6396 67.9131 57.7434 68.1901 57.7701C68.4671 57.7968 68.7459 57.7452 68.995 57.6212C69.2441 57.4973 69.4537 57.3059 69.5995 57.0689C69.7453 56.8319 69.8217 56.5588 69.82 56.2805V23.7116C69.8202 23.4369 69.7442 23.1676 69.6007 22.9334C69.4572 22.6992 69.2517 22.5094 69.0069 22.3849ZM50.7818 53.9543H14.0938V25.1989H50.7818V53.9543ZM66.8453 53.2761L53.7565 43.2474V36.1518L66.8453 26.6327V53.2761Z"
          fill="#313535"
        />
      </g>
      <defs>
        <clipPath id="clip0_176_218">
          <rect
            width="58.7027"
            height="35.5556"
            fill="white"
            transform="translate(11.1113 22.2222)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
