export const Check = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00001 16.5C6.41779 16.5 4.87109 16.0308 3.55552 15.1518C2.23994 14.2728 1.21456 13.0234 0.609035 11.5616C0.00351564 10.0998 -0.154958 8.49131 0.153672 6.93949C0.462301 5.38766 1.22418 3.96223 2.34293 2.84338C3.46169 1.72454 4.88708 0.962558 6.43888 0.653803C7.99069 0.345049 9.59919 0.503371 11.061 1.10877C12.5228 1.71417 13.7723 2.73947 14.6515 4.05498C15.5306 5.37048 15.9999 6.91711 16 8.49934C16.0001 9.54996 15.7932 10.5903 15.3912 11.561C14.9892 12.5317 14.4 13.4137 13.6571 14.1566C12.9142 14.8996 12.0323 15.4889 11.0616 15.891C10.091 16.293 9.05064 16.5 8.00001 16.5ZM4.36106 7.7107L3.41677 8.65501L6.3039 11.5389C6.36562 11.6011 6.43903 11.6504 6.51989 11.684C6.60076 11.7177 6.68748 11.7351 6.77507 11.7351C6.86267 11.7351 6.9494 11.7177 7.03027 11.684C7.11114 11.6504 7.18453 11.6011 7.24624 11.5389L12.5794 6.2058L11.637 5.26342L6.77669 10.1289L4.36106 7.7107Z"
        fill="#717C7C"
      />
    </svg>
  );
};
