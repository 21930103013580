import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import it from './translations/it.json';
import en from './translations/en.json';
import gr from './translations/gr.json';

export const resources = {
  it,
  en,
  gr
} as const;

export const availableLanguages = Object.keys(resources);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    defaultNS: 'common',
    fallbackLng: 'en',
    detection: {
      order: ['navigator'],
      caches: ['localStorage', 'cookie']
    }
  });
