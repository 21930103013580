import AboutUs from './aboutUs/AboutUs';
import classes from './Home.module.scss';
import UseCases from './useCases/UseCases';
import Footer from './footer/Footer';
import Hero from './hero/Hero';
import Navbar from './navbar/Navbar';
import Applications from './applications/Applications';
import TechPartners from './techPartners/TechPartners';
import Feature from './feature/Feature';
import OurRobots from './ourRobots/OurRobots';
import ContactsBanner from './contactsBanner/ContactsBanner';
import { FC } from 'react';

const Home: FC = () => {
  return (
    <div className={classes.bodyContainer}>
      <Navbar />
      <div className={classes.routesContainer}>
        <Hero />
        <AboutUs />
        <div className={classes.background}>
          <Feature />
          <Applications />
          <UseCases />
        </div>
        <OurRobots />
        <ContactsBanner />
        <TechPartners />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
