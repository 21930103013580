import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from 'views/home/Home';
import Privacy from 'views/privacy/Privacy';

const Routing = () => {
  const { i18n } = useTranslation();
  return (
    <Routes>
      <Route path="/:lang" element={<Home />} />
      <Route path="/privacy/:lang" element={<Privacy />} />
      <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
    </Routes>
  );
};

export default Routing;
