export const Aerial = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_176_273)">
        <path
          d="M47.312 24.5979C47.3131 23.2806 46.9635 21.9867 46.2994 20.849C45.6353 19.7114 44.6805 18.7709 43.5329 18.1242C42.3853 17.4774 41.0863 17.1477 39.7692 17.1688C38.4521 17.1899 37.1642 17.5611 36.0379 18.2443C34.9116 18.9274 33.9874 19.898 33.36 21.0564C32.7327 22.2147 32.4248 23.5191 32.4681 24.8357C32.5114 26.1523 32.9042 27.4337 33.6063 28.5483C34.3084 29.663 35.2945 30.5707 36.4632 31.1784L26.3271 66.6667H29.6174L39.892 56.394L50.1646 66.6667H53.4551L43.3149 31.1784C44.518 30.5504 45.5263 29.6053 46.2308 28.4454C46.9353 27.2855 47.3093 25.955 47.312 24.5979ZM35.4338 24.5979C35.4338 23.7169 35.6949 22.8557 36.1844 22.1232C36.6738 21.3907 37.3695 20.8198 38.1834 20.4826C38.9973 20.1455 39.8931 20.0572 40.7571 20.2291C41.6212 20.401 42.4147 20.8252 43.0377 21.4482C43.6607 22.0711 44.085 22.8648 44.2569 23.7289C44.4288 24.5929 44.3405 25.4886 44.0034 26.3025C43.6662 27.1164 43.0952 27.8121 42.3627 28.3015C41.6302 28.791 40.7691 29.0522 39.8881 29.0522C38.7072 29.0507 37.5752 28.5809 36.7402 27.7458C35.9052 26.9108 35.4353 25.7788 35.4338 24.5979ZM39.89 39.1448L37.8569 37.1136L39.3199 31.9961C39.6986 32.0317 40.0796 32.0317 40.4582 31.9961L41.9212 37.1136L39.89 39.1448ZM36.2315 42.8013L36.9243 40.3801L37.7895 41.2452L36.2315 42.8013ZM39.89 43.3437L44.3146 47.7684L39.89 52.195L35.4635 47.7684L39.89 43.3437ZM41.9886 41.2452L42.8536 40.3801L43.5445 42.7993L41.9886 41.2452ZM37.7895 54.2935L31.013 61.07L34.0241 50.5281L37.7895 54.2935ZM48.7651 61.07L41.9886 54.2935L45.7499 50.5321L48.7651 61.07Z"
          fill="#313535"
        />
        <path
          d="M29.3226 35.8644L31.4231 33.7639L31.0727 33.4155C29.9129 32.2587 28.9927 30.8846 28.3648 29.3717C27.737 27.8588 27.4139 26.2368 27.4139 24.5988C27.4139 22.9608 27.737 21.3389 28.3648 19.826C28.9927 18.3131 29.9129 16.9389 31.0727 15.7822L31.4231 15.4318L29.3226 13.3333L28.9741 13.6817C27.5382 15.1139 26.399 16.8154 25.6217 18.6885C24.8444 20.5617 24.4443 22.5698 24.4443 24.5979C24.4443 26.6259 24.8444 28.634 25.6217 30.5072C26.399 32.3803 27.5382 34.0818 28.9741 35.514L29.3226 35.8644Z"
          fill="#313535"
        />
        <path
          d="M48.3555 33.7639L50.456 35.8644L50.8042 35.514C52.2401 34.0818 53.3795 32.3803 54.1568 30.5072C54.9341 28.634 55.3342 26.6259 55.3342 24.5979C55.3342 22.5698 54.9341 20.5617 54.1568 18.6885C53.3795 16.8154 52.2401 15.1139 50.8042 13.6817L50.456 13.3333L48.3555 15.4318L48.7059 15.7822C49.8656 16.9389 50.7857 18.3131 51.4135 19.826C52.0413 21.3389 52.3647 22.9608 52.3647 24.5988C52.3647 26.2368 52.0413 27.8588 51.4135 29.3717C50.7857 30.8846 49.8656 32.2587 48.7059 33.4155L48.3555 33.7639Z"
          fill="#313535"
        />
      </g>
      <defs>
        <clipPath id="clip0_176_273">
          <rect
            width="30.8894"
            height="53.3333"
            fill="white"
            transform="translate(24.4443 13.3333)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
