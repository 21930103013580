import { FC } from 'react';
import { Gymo, Unitree } from 'components/icons';
import classes from './TechPartners.module.scss';
import { useTranslation } from 'react-i18next';

const TechPartners: FC = () => {
  const { t } = useTranslation('techPartners');

  return (
    <section className={classes.container}>
      <h3 className="blue">{t('title')}</h3>
      <div>
        <Gymo />
        <Unitree />
      </div>
    </section>
  );
};

export default TechPartners;
