import { FC, useEffect } from 'react';
import classes from './Navbar.module.scss';
import LangMenu from 'components/langMenu/LangMenu';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from 'i18n';

const Navbar: FC = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!i18n) return;
    const path = document.location.pathname.concat('');
    const split = path.split('/');
    const loc = split[split.length - 1];
    setTimeout(() => {
      i18n.changeLanguage(loc);
    }, 10);
  }, [i18n]);

  useEffect(() => {
    const path = document.location.pathname.concat('');
    const split = path.split('/');
    const loc = split[split.length - 1];
    const lastSlashIndex = path.lastIndexOf('/');
    const locDef = path.substring(0, lastSlashIndex + 1);
    if (!availableLanguages.includes(loc)) {
      setTimeout(() => {
        navigate(`${locDef}en`);
      }, 10);
    }
  }, [navigate]);

  return (
    <nav className={classes.container}>
      <div className={classes.logo} />
      <div className={classes.langButton}>
        <LangMenu />
      </div>
    </nav>
  );
};

export default Navbar;
