import Routing from 'Routing';
import React from 'react';
import 'styles/main.scss';

function App() {
    return <Routing />;
}

export default App;

